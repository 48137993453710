<template>
  <v-container fluid>
    <BackButton :handler="goToBlogs"/>
    <v-container fluid no-gutters style="max-width: 95% !important;" class="form_container">
      <v-form ref="form" v-model="valid">
        <h3 class="text-base font-semibold black-text ml-2">Blog Details</h3>
        <v-card class="rounded shadow-0 bordered mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="7" sm="12" class="pr-8">
                <v-row dense>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Title*</label>
                    <v-text-field
                        v-model="blog.title"
                        outlined
                        required
                        :rules="[(v) => !!v || 'Title is required']"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        hide-details="auto"
                        :error-messages="errors.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Excerpt</label>
                    <v-textarea
                        v-model="blog.excerpt"
                        outlined
                        required
                        class="q-text-field shadow-0"
                        dense
                        rows="4"
                        validate-on-blur
                        hide-details="auto"
                        :rules="[(v) => !!v || 'Excerpt is required']"
                        :error-messages="errors.excerpt"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-items-center gap-x-4">
                      <v-switch
                          :false-value="0"
                          :true-value="1"
                          v-model="blog.is_featured"
                          class="my-0 v-input--reverse"
                          dense
                          hide-details
                      >
                        <template #label>
                          Is Featured
                        </template>
                      </v-switch>
                      <v-switch
                          :false-value="0"
                          :true-value="1"
                          v-model="blog.is_public"
                          class="mx-4 my-0 v-input--reverse"
                          dense
                          hide-details="auto"
                      >
                        <template #label>
                          Enable Online
                        </template>
                      </v-switch>
                      <v-switch
                          :false-value="0"
                          :true-value="1"
                          class="mx-4 my-0"
                          @change="changedSeo"
                          v-model="blog.enable_seo_meta"
                      >
                        <template #label>
                          SEO Meta
                        </template>
                      </v-switch>
                    </div>
                    <div class="d-flex align-items-center gap-x-4" v-if="blog.enable_seo_meta == 1">
                      <v-row justify="center" class="my-4">
                        <v-col cols="6">
                          <p class="">
                            <i>The recommended length 50 to 60 characters</i>
                          </p>
                          <label for="">SEO Meta Title</label>
                          <v-text-field
                              label=""
                              outlined
                              v-model="blog.seo_meta.title"
                              class="q-text-field shadow-0"
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <p class="">
                            <i>The recommended length 120 to 155 characters</i>
                          </p>
                          <label for="">SEO Meta Description</label>
                          <v-text-field
                              label=""
                              outlined
                              v-model="blog.seo_meta.description"
                              class="q-text-field shadow-0"
                              dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="5" sm="12" lg="5">
                <div style="height: 200px">
                  <image-uploader
                      @upload="(data) => {blog.banner = data;}"
                      @remove="() => {blog.banner = null;}"
                      :image_path="bannerImage"
                      defaultImage="blog"
                      messagePosition="inside"
                      text="Blog Banner"
                      :height="150"
                  ></image-uploader>
                  <small class="error--text" v-if="errors.banner">
                    {{ errors.banner }}
                  </small>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="mt-4" dense>
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1">Description</h3>
          </v-col>
          <v-col cols="12">
            <ckeditor v-model="blog.content" :editor="editor" style="min-height: 400px" :config="editorConfig"
                      id="editor"/>
            <small class="error--text" v-if="errors.content">
              {{ errors.content }}
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-btn class="ma-2 shadow-0 text-white" color="red" @click="deleteBlog"
                 v-if="blog.id && checkDeletePermission($modules.b2cconfig.blogs.slug)">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color shadow-0" @click="addOrEditBlog"
                 v-if="checkWritePermission($modules.b2cconfig.blogs.slug)">
            {{ blog.id != null ? "Update" : "Add" }} blog
          </v-btn>
        </v-row>
      </v-form>

      <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
      ></confirm-model>
    </v-container>
  </v-container>
</template>
<script>
import BackButton from "@/components/Common/BackButton.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {CustomUploadAdapterPlugin} from "@/plugins/UploadAdapter";
// import {UploadAdapter} from "@/plugins/UploadAdapter";

export default {
  components: {
    ImageUploader,
    BackButton,
    ckeditor: CKEditor.component
  },
  mounted() {
    this.blogId = this.$route.params.id ? atob(this.$route.params.id) : null;
    if (this.blogId) {
      this.getBlog(this.blogId);
    }
  },
  computed: {
    bannerImage() {
      if (this.blog.banner && this.blog.banner.file_path) {
        return this.blog.banner.file_path;
      }
      return this.blog.banner && typeof this.blog.banner === 'string' ? this.blog.banner : null;
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      valid: true,
      blogId: null,
      blog: {
        title: null,
        content: '',
        banner: null,
        is_featured: 0,
        excerpt: null,
        is_public: 0,
        enable_seo_meta: 0,
        seo_meta: {
          title: "",
          description: "",
        },
      },
      editorConfig: {
        extraPlugins: [CustomUploadAdapterPlugin],
        mediaEmbed: {previewsInData: true}
      },
      form: null,
      errors: {},
      confirmModel: {
        id: null,
        title: null,
        description: null,
      }
    }
  },
  methods: {
    changedSeo(){
      this.$forceUpdate();
    },
    getBlog(id) {
      this.showLoader("Loading")
      this.$http
          .get(`venues/b2c/blogs/${id}`)
          .then((response) => {
            if (response.status === 200 && response.data.status) {
              this.blog = response.data?.data || [];
              this.blog.enable_seo_meta = 0;
              if(this.blog.seo){
                this.blog.enable_seo_meta = 1;
                let meta = {};
                this.blog.seo.forEach((ele) => {
                  meta = {...meta,[ele.key]:ele.value}
                })
                this.blog.seo_meta = meta;
              }
            }
          })
          .catch((error) => {
            this.showError(error)
          })
          .finally(() => {
            this.hideLoader()
          });
    },
    goToBlogs() {
      this.$router.push({name: "B2CBlogs"});
    },
    addOrEditBlog() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all fields");
        return;
      }
      this.showLoader("Please wait...")
      this.errors = {};
      const formData = new FormData();
      Object.keys(this.blog).forEach((key) => {
        if (this.blog[key] !== null && this.blog[key] !== undefined && key !== 'seo_meta') {
          formData.append(key, this.blog[key]);
        }
      });

      if(this.blog.enable_seo_meta){
        formData.append('seo[0][key]', 'title');
        formData.append('seo[0][value]', this.blog.seo_meta.title);
        formData.append('seo[1][key]', 'description');
        formData.append('seo[1][value]', this.blog.seo_meta.description);
      }

      this.$http
          .post(`venues/b2c/blogs` + (this.blogId ? '/' + this.blogId : ''), formData)
          .then((response) => {
            if (response.status === 200 && response.data.status) {
              this.showSuccess(response.data.message);
              this.$router.push({name: "B2CBlogs"});
            }
          })
          .catch((error) => {
            if (error.response?.status === 422) {
              Object.keys(error.response.data.data).forEach((key) => {
                this.errors = {
                  ...this.errors,
                  [key]: error.response.data.data[key][0]
                }
              })
            }
            this.showError(error)
          })
          .finally(() => {
            this.hideLoader()
          });
    },
    deleteBlog() {
      this.confirmModel = {
        id: this.blogId,
        title: "Delete Blog",
        description: "Are you sure you want to delete this blog?",
        type: 'delete'
      }
    },
    confirmActions(data) {
      if (data.type === 'delete') {
        this.$http
            .delete(`venues/b2c/blogs/${data.id}`)
            .then((response) => {
              if (response.status === 200 && response.data.status) {
                this.showSuccess(response.data.message);
                this.$router.push({name: "B2CBlogs"});
              }
            })
            .catch((error) => {
              this.showError(error)
            })
      }
    }
  }
  ,
}

</script>
<style>
.ck-sticky-panel__content {
  top: 65px !important;
}
</style>